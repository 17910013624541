.main_header
{
    width: 100vw;
    height: calc(100vh - 15rem);
    display: grid;
    place-items: center;
    margin-top: 3rem;
}
.main_header-container
{
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
}
.main_header-left
{
    margin-top: 4rem;
}
.main_header-left h1
{
    font-family: 'SecularOne-Regular';
    color: #000;
    font-size: 5rem;
    font-family: 'Acme', sans-serif;
    text-transform: uppercase;
}

.main_header-left h3
{
    color: var(--color-secondary);
    font-weight: 400;
    font-size: 1.5rem;
    margin-top: 1rem;
    text-transform: uppercase;
    font-family: 'SecularOne-Regular';
}
.main_header-left p
{
    margin: 1rem 0 2rem;
    font-size: 3rem;
    font-family: 'Tangerine', sans-serif;
    font-weight: 'bold';
    color: #000;
}
.main_header-right
{
    display: grid;
    place-items: center;
    position: relative;
}
.main_header-image
{
    position: relative;
    margin-top: 2rem;
    animation: animate 2s linear infinite;
    width: 70%;
}
.htitle
{
    color: #000;
    margin-top: 2rem;
    font-size: 2rem;
    text-align: center;
    margin-left: 2rem;
}
@keyframes animate
{
  0%
  {
    filter: hue-rotate(0deg);
  }
  100%
  {
    filter: hue-rotate(360deg);
  }
}

/*************SERVICES*****************/

.services
{
    margin-top: 4rem;
}
.services_wrapper
{
    width: 100%;
    display: grid;
    margin-top: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(4,1fr);
}
.services_services h4
{
    margin-top: 2rem;
}
.services_services a
{
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.services_services:hover a
{
    background: var(--color-secondary);
}
/*********************LOCATIONS*********************/
.location
{
    height: 45vh;
    width: 100vw;
    margin-top: 3rem;
    background-color: #fff;
    place-items: center;
    border-top: 1px solid black;
}
.location h2
{
    font-size: 2.3rem;
    position: absolute;
    left: 32%;
    color: #000;
    margin-top: 3rem;
}
.areas
{
    width: 100%;
    display: inline-block;
    list-style-type: none;
}
.location_areas
{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    position: relative;
    color: #000;
    top: 7rem;
    left: 3rem;
}
.location_areas li
{
    margin-bottom: 1rem;
    font-size: 12px;
    margin-top: 0.4rem;
    color: #000;
    font-family: 'Hammersmith One', sans-serif;
    word-spacing: 1px;
}
.location_areas li h3
{
    color: #000;
}

/*******************FOOTER*******************/

footer
{
    background: #fff;
    margin-top: 0.1rem;
    padding-top: 3rem;
    font-size: 0.9rem;
    color: var(--color-gray-100);
}
.footer_container
{

    display: grid;
    grid-template-columns: 15rem 1fr 1fr 1fr;
    gap: 6rem;
}
.group
{
    color: #000;
    font-size: 2rem;
    margin-left: 1.5rem;
}
.flogo
{
    width: 9vw;
    margin-left: 4rem;
    filter: drop-shadow(0.9rem 0.5rem 1rem black);
}
.gname
{
    text-align: center;
}
.footer_container article
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
    color: #000;
}
.footer_container article h3
{
    color: #000;
}
.imail
{
    color: #000;
    font-weight: bold;
    font-size: 1rem;
    margin-top: 2rem;
    margin-left: -4rem;
    position: absolute;
}
.sfont
{
    color: #000;
    font-weight: bold;
}
.footer_container article .value
{
    margin-top: 0.8rem;
    font-size: 19px;
    position: relative;
    color: #000;
}
.footer_container article h4
{
    margin-bottom: 0.6rem;
    font-size: 1rem;
    color: #000;
}
.mapouter
{
    position: relative;
    margin-top: -22rem;
    margin-left: 30rem;
}
iframe
{
    width: 572px;
    height: 300px;
}
.footer_social
{
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}
.footer_social a
{
    background: var(--color-gray-100);
    padding: 0.7rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
}
.footer_social a svg
{
    color: var(--color-gray-600);
}
.footer_social a:hover
{
    background: var(--color-gray-100);
    background-color: transparent;
}
.location_container .address
{
    font-size: 15px;
    position: absolute;
    color: #000;
    font-weight: bold;
    margin-top: 2.5rem;
    margin-right: 3rem;
}
.footer_copyright
{
    color: var(--color-gray-100);
    text-align: center;
    padding: 1rem 0;
    border-top: 3px dashed #fff;
    margin-top: 2rem;
    font-size: 1.2rem;
    text-shadow: 3px #fff;
    font-family: 'Secular One', sans-serif;
    background: #000;
}

/** MEDIA QUERIES ***/

@media screen and (max-width:1024px) {

    .main_header
    {
        height: fit-content;
        padding: 2rem 0;
    }
    .main_header-container
    {
        gap: 0;
    }
    .main_header-right
    {
        display: grid;
        place-items: center;
        position: relative;
    }
    .main_header-image
    {
        position: relative;
        margin-top: 2rem;
        width: 70%;
    }
}
@media screen and (max-width:768px) {

    .main_header
    {
        height: fit-content;
    }
    .main_header-container
    {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
    }
    .main_header-right
    {
        display: grid;
        place-items: center;
        position: relative;
    }
    .main_header-left h1
    {
        font-family: 'Acme', sans-serif;
    }
    .main_header-image
    {
        position: relative;
        margin-top: -2rem;
        width: 60%;
        transition: ease-in;
    }
    .main_header-left h1
    {
        font-family: 'SecularOne-Regular';
        color: #000;
        font-weight: bolder;
        font-family: 'Acme', sans-serif;
        text-transform: uppercase;
        font-size: 5rem;
    }
    .main_header-left h3
    {
        color: var(--color-secondary);
        font-weight: bold;
        font-size: 2rem;
        margin-top: 1.5rem; 
        transition: ease-in 10s linear;
        text-transform: uppercase;
        font-family: 'SecularOne-Regular';
    }
    .main_header-left p
    {
        margin: 1rem 0 2rem;
        font-size: 3rem;
        font-family: 'Tangerine', cursive;
        font-weight: 'bold';
        color: #000;
    }
}
@media screen and (max-width:425px) {

    .main_header
    {
        height: fit-content;
        padding: 1rem 0;
    }
    .main_header-container
    {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
    }
    .main_header-right
    {
        display: grid;
        place-items: center;
        position: relative;
    }
    .main_header-image
    {
        position: relative;
        margin-top: -2rem;
        width: 80%;
        transition: ease-in;
    }
    .main_header-left h1
    {
        font-family: 'SecularOne-Regular';
        color: #000;
        text-transform: uppercase;
        font-family: 'Acme', sans-serif;
        font-size: 3rem;
    }
    .main_header-left h3
    {
        color: var(--color-secondary);
        font-weight: 400;
        font-size: 1.2rem;
        margin-top: 1.5rem; 
        transition: ease-in 10s linear;
        text-transform: uppercase;
        font-family: 'SecularOne-Regular';
    }
    .main_header-left p
    {
        margin: 1rem 0 2rem;
        font-size: 2rem;
        font-family: 'Tangerine', cursive;
        font-weight: 'bold';
        color: #000;
    }
}
@media screen and (max-width:1024px)
{
    .location
    {
        height: 30vh;
        width: 100%;
        margin-top: 3rem;
        background-color: #fff;
        place-items: center;
    }
    .location h2
    {
        font-size: 2.3rem;
        position: absolute;
        left: 22%;
        margin-top: 3rem;
        border-bottom: 2px dashed #fff;
    }
    .areas
    {
        width: 100%;
        display: inline-block;
        list-style-type: none;
    }
    .location_areas
    {
        display: grid;
        grid-template-columns: repeat(5,1fr);
        position: relative;
        top: 7rem;
        left: 3rem;
    }
    .location_areas li
    {
        margin-bottom: 1rem;
        font-size: 12px;
        margin-top: 0.9rem;
        font-family: 'Hammersmith One', sans-serif;
        word-spacing: 1px;
    }    
}
@media screen and (max-width:768px)
{
    .location
    {
        height: 40vh;
        width: 100vw;
        margin-top: 3rem;
        background-color: #fff;
        place-items: center;
    }
    .location h2
    {
        font-size: 2rem;
        position: absolute;
        left: 19%;
        margin-top: 3rem;
        border-bottom: 2px dashed #fff;
    }
    .areas
    {
        width: 100%;
        display: inline-block;
        list-style-type: none;
    }
    .location_areas
    {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        position: relative;
        top: 7rem;
        left: 1rem;
    }
    .location_areas li
    {
        margin-bottom: 1rem;
        font-size: 10px;
        margin-top: 0.9rem;
        font-family: 'Hammersmith One', sans-serif;
        word-spacing: 1px;
    }    
}
@media screen and (max-width:425px)
{
    .location
    {
        height: 45vh;
        width: 100vw;
        margin-top: 3rem;
        background-color: #fff;
        place-items: center;
    }
    .location h2
    {
        font-size: 1rem;
        position: absolute;
        left: 19%;
        margin-top: 2rem;
        border-bottom: 2px dashed #fff;
    }
    .areas
    {
        width: 100%;
        display: inline-block;
        list-style-type: none;
    }
    .location_areas
    {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        position: relative;
        top: 4rem;
    }
    .location_areas li
    {
        margin-bottom: 1rem;
        font-size: 8px;
        margin-top: 0.9rem;
        font-family: 'Hammersmith One', sans-serif;
        word-spacing: 1px;
    }    
}
@media screen and (max-width:1024px){
    footer
    {
        background: #fff;
        margin-top: 0.1rem;
        padding-top: 3rem;
        width: 100%;
        font-size: 0.9rem;
        color: var(--color-gray-100);
    }
    .footer_container
    {
        display: grid;
        grid-template-columns: 15rem 1fr 1fr 1fr;
        gap: 4rem;
    }
    .flogo
    {
        width: 30%;
        margin-left: 4rem;
    }
    .footer_container article
    {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.7rem;
    }   
    .mapouter
    {
        margin-left: 24rem;
        margin-top: -22rem;
    }
    iframe
    {
        width: 540px;
        height: 350px;
    }
    .footer_container article .value
    {
        margin-top: 0.8rem;
        font-size: 18px;
        position: relative;
    }
    .footer_container article h4
    {
        margin-bottom: 0.6rem;
        font-size: 1rem;
    }
    .footer_social
    {
        margin-top: 1.5rem;
        display: flex;
        gap: 1rem;
        align-items: center;
    }
    .footer_social a
    {
        background: var(--color-gray-100);
        padding: 0.7rem;
        border: 1px solid transparent;
        border-radius: 0.6rem;
        transition: var(--transition);
    }
    .footer_social a svg
    {
        color: var(--color-gray-600);
    }
    .footer_social a:hover
    {
        background: var(--color-gray-100);
        background-color: transparent;
    }
    .location_container .address
    {
        font-size: 10px;
        position: absolute;
        margin-top: 2.5rem;
        margin-right: 2rem;
    }
    .footer_copyright
    {
        color: var(--color-gray-100);
        text-align: center;
        padding: 1rem 0;
        border-top: 3px dashed #fff;
        margin-top: 2rem;
    }
}
@media screen and (max-width:768px)
{
    footer
    {
        background: #fff;
        margin-top: 0.1rem;
        padding-top: 3rem;
        width: 100%;
        font-size: 0.9rem;
        color: var(--color-gray-100);
    }
    .footer_container
    {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 5rem;
    }
    .group
    {
        margin-left: 2.6rem;
    }
    .flogo
    {
        width: 25%;
        margin-left: 6rem;
    }
    .footer_container article
    {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.6rem;
        margin-left: 1rem;
        font-size: 0.7rem;
    }   
    .footer_container article .value
    {
        margin-top: 0.6rem;
        font-size: 16px;
        position: relative;
    }
    .footer_container article h4
    {
        margin-bottom: 0.6rem;
        font-size: 1rem;
    }
    .footer_social
    {
        margin-top: 1.5rem;
        display: flex;
        gap: 1rem;
        align-items: center;
    }
    .imail
    {
        margin-left: 0.1rem;
    }
    .mapouter
    {
        margin-left: 107%;
        margin-top: -25rem;
    }
    iframe
    {
        width: 255px;
        height: 190px;
    }
    .footer_social a
    {
        background: var(--color-gray-100);
        padding: 0.7rem;
        border: 1px solid transparent;
        border-radius: 0.6rem;
        transition: var(--transition);
    }
    .footer_social a svg
    {
        color: var(--color-gray-600);
    }
    .footer_social a:hover
    {
        background: var(--color-gray-100);
        background-color: transparent;
    }
    .location_container .address
    {
        font-size: 13px;
        position: absolute;
        margin-top: 1.4rem;
        margin-right: 2rem;
    }
    .footer_copyright
    {
        color: var(--color-gray-100);
        text-align: center;
        padding: 1rem 0;
        border-top: 3px dashed #fff;
        margin-top: 2rem;
        text-shadow: 3px #fff;
        font-weight: bold;
        background: #000;
    }
}

@media screen and (max-width:425px){
    footer
    {
        background: #fff;
        margin-top: 0.1rem;
        padding-top: 2rem;
        width: 100%;
        font-size: 0.9rem;
        color: var(--color-gray-100);
    }
    .footer_container
    {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 3rem;
    }
    .group
    {
        font-size: 1rem;
        margin-left: 1.5rem;
    }
    .gname
    {
        font-size: 10px;
        margin-left: -1rem;
    }
    .flogo
    {
        width: 20vw;
        margin-left: 2rem;
        filter: drop-shadow(0.9rem 0.5rem 0.7rem black);
    }
    .footer_container article
    {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.7rem;
        font-size: 0.6rem;
    }   
    .footer_container article .value
    {
        margin-top: 0.8rem;
        font-size: 10px;
        position: relative;
    }
    .footer_container article h4
    {
        margin-bottom: 0.6rem;
        font-size: 0.7rem;
    }
    .footer_social
    {
        margin-top: 1.5rem;
        display: flex;
        gap: 1rem;
        align-items: center;
    }
    .footer_social a
    {
        background: var(--color-gray-100);
        padding: 0.7rem;
        border: 1px solid transparent;
        border-radius: 0.6rem;
        transition: var(--transition);
    }
    .footer_social a svg
    {
        color: var(--color-gray-600);
    }
    .footer_social a:hover
    {
        background: var(--color-gray-100);
        background-color: transparent;
    }
    .sfont
    {
        font-size: 10px;
    }
    .imail
    {
        font-size: 10px;
        margin-left: 0.1rem;
    }
    .mapouter
    {
        margin-left: 102%;
        margin-top: -18rem;
    }
    iframe
    {
        width: 160px;
        height: 190px;
    }
    .location_container .address
    {
        font-size: 7px;
        position: absolute;
        margin-top: 2rem;
        margin-right: 1rem;
    }
    .footer_copyright
    {
        color: var(--color-gray-100);
        text-align: center;
        padding: 1rem 0;
        border-top: 3px dashed #fff;
        margin-top: 2rem;
        text-shadow: 3px #fff;
        font-weight: bold;
        background: #000;
    }

}
@media only screen and (max-width:1444px)
{
    .services_wrapper
    {
        display: grid;
        gap: 1rem;
        list-style-type: circle;
        margin-top: 2rem;
        margin-left: 0.1rem;
        grid-template-columns: repeat(4,1fr);
    }
}
@media only screen and (max-width:1024px)
{
    .services_wrapper
    {
        display: grid;
        gap: 1rem;
        list-style-type: circle;
        margin-top: 2rem;
        margin-left: 2rem;
        grid-template-columns: repeat(2,1fr);
    }
    .htitle
    {
        margin-top: 5rem;
    }
}
@media screen and (max-width:768px)
{
    .services_wrapper
    {
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (max-width:2532px)
{
    .services_wrapper
    {
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (max-width:425px)
{
    .services_wrapper
    {   
        display: grid;
        gap: 0.1rem;
        list-style-type: circle;
        margin-top: 2rem;
        margin-left: -0.5rem;
        grid-template-columns: repeat(2,1fr);
    }
    .btext
    {
        font-size: 8px;
        margin-top: 1%;
    }
}