.main-video-player
{
    width: 50%;
    position: relative;
    top: 13rem;
}
.video
{
    width: 100%;
}
.main-gallery-wrapper
{
    width: 50%;
    position: absolute;
    top: 35.5rem;
    left: 50%;
}
.swiper
{
    width: 90%;
    height: 50vh;
    position: absolute;
    left: 2%;
    top: 50%;
    margin-left: -150px;
    margin-top: -150px;
}
.swiper-slide 
{
    background-position: center;
    background-size: cover;
}
@media screen and (min-width:1200px) and (max-width:1444px)
{
    .video
    {
        width: 100%;
        margin-left: 1rem;
        position: relative;
        top: 6rem;
        left: 1px;
    }
    .main-gallery-wrapper
    {
        width: 50%;
        position: absolute;
        top: 37rem;
        left: 50%;
    }
    .swiper
    {
        width: 90%;
        height: 50vh;
        position: absolute;
        left: 2%;
        top: 54%;
        margin-left: -150px;
        margin-top: -150px;
    }
    .swiper-slide 
    {
        background-position: center;
        background-size: cover;
    }
}
@media screen and (min-width:600px) and (max-width:800px)
{
    .video
    {
        width: 93%;
        position: absolute;
        top: 55%;
    }
    .main-gallery-wrapper
    {
        width: 100%;
        position: absolute;
        top: 115%;
        left: -1%;
    }
    .swiper
    {
        width: 90%;
        height: 40vh;
        position: absolute;
        left: 1%;
        top: 48%;
        margin-left: -150px;
        margin-top: -150px;
    }
}
@media screen and (max-width:425px)
{
    .main-video-player
    {
        width: 100%;
        position: relative;
        top: 15rem;
    }
    .video
    {
        width: 350px;
    }
    .main-gallery-wrapper
    {
        width: 100%;
        position: absolute;
        top: 50rem;
        left: -1%;
    }
    .video_swiper
    {
        width: 100%;
        height: 30vh;
        position: relative;
        left: 1%;
        top: 50%;
        margin-left: -150px;
        margin-top: -150px;
    }
    .swiper
    {
        width: 90%;
        height: 30vh;
        position: absolute;
        left: 1%;
        top: 50%;
        margin-left: -150px;
        margin-top: -150px;
    }
}