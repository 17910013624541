.gallery_container
{
    margin-top: 5rem;
    background: #fff;
    box-sizing: border-box;
}
.gallerytitle
{
    font-weight: bold;
    text-align: center;
}
.gallerytitle h1
{
    color: black;
    position: relative;
    top: 20px;
    letter-spacing: 1px;
    text-shadow: 0.2rem black;
    text-transform: uppercase;
    font-family: 'Acme', sans-serif;
}
.gallery_grid
{
    margin-top: 4rem;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
}
.gallery_grid .pics
{
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}
.gallery_grid .pics:hover
{
   filter: opacity(.8);
}
@media only screen and (max-width:425px)
{
    .gallery_grid
    {
        margin-top: 2rem;
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 33%;
        -moz-column-width: 33%;
        column-width: 33%;
    }
    
}