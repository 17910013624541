*,*::before, *::after
{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}
:root
{
    --primary-hue: 210;
    --gray-hue: 240;
    --color-primary : hsl(var(--primary-hue),100%,50%);
    --color-primary-variant : hsl(var(--primary-hue),100%,65%);
    --color-secondary : #ffb116;
    --color-gray-100 : hsl(var(--gray-hue),47%,94%);
    --color-gray-200 : hsl(var(--gray-hue),26%,75%);
    --color-gray-300 : hsl(var(--gray-hue),24%,64%);
    --color-gray-400 : hsl(var(--gray-hue),16%,41%);
    --color-gray-500 : hsl(var(--gray-hue),44%,25%);
    --color-gray-600 : hsl(var(--gray-hue),48%,15%);

    --container-width-lg : 80%;
    --container-width-md : 90%;

    --transition: all 500ms ease;

}
body
{
    font-family: 'Secular One', sans-serif;
    color: var(--color-gray-200);
    line-height: 1.7;
    overflow-x: hidden;
    background: rgba(255, 255, 255, 0.559);
}
.container
{
    width: var(--container-width-lg);
    max-width: 1920px;
    margin-inline: auto;
}
h1,h2,h3,h4,h5,h6
{
    line-height: 1.2;
    color: var(--color-gray-100);
}
h1
{
    font-size: 3rem;
}
a
{
    color: var(--color-gray-100);
}
img 
{
    object-fit: cover;
    width: 100%;
    display: block;
}
@font-face {
	font-family: BungeeShade-Regular;
	src: url('./assets/font/BungeeShade-Regular.ttf');
	font-weight: 700;
}
@font-face {
	font-family: SecularOne-Regular;
	src: url('./assets/font/SecularOne-Regular.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Akronim-Regular;
	src: url('./assets/font/Akronim-Regular.ttf');
	font-weight: 700;
}
.btnbook
{
    position: absolute;
    padding: 0.7rem;
    font-size: 13px;
    top: 10rem;
    border: 1px outset #fff;
    left: 4.5rem;
    text-align: center;
    color: #fff;
    background:var(--color-primary);
    box-shadow: 0 3px 2px 0 rgba(31, 38, 135, 0.685);
    backdrop-filter: blur( 4.5px );
    -webkit-backdrop-filter: blur( 4.5px );
    border-radius: 50px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.btnservices
{
    padding: 1.2rem 1.2rem;
    border-radius: 2rem;
    font-size: 1.5rem;
    margin-bottom: -1rem;
    color: #fff;
    position: relative;
    left: 25rem;
    text-align: center;
    background: var(--color-primary);
}
.btnservices:hover
{
    background: orange;
    border: 2px double white;
}


section
{
    margin-top: 2rem;
}
.section_head
{
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 3rem;
}
.section_head span
{
    background: var(--color-gray-500);
    padding: 0.8rem;
    border-radius: 15rem;
    margin-left: 18rem;
    font-family: 'Akronim-Regular', serif;
    color: var(--color-secondary);
}
.section_head h2
{
    font-family: 'Akronim-Regular', serif;
    font-size: 5rem;
    color: #000;
}
.icon
{
    width: 50%;
    height: 50%;
    margin-top: 3rem;
    position: absolute;
    left: 20%;
}






.card
{
    background: #fff;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4.5px );
    -webkit-backdrop-filter: blur( 4.5px );
    border-radius: 10px;
    border: 1px solid gray;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 21vw;
    height: 40vh;
    align-content: center;
    justify-content: center;
    transition: var(--transition);
}
.card span
{
    width: 10rem;
    height: 5rem;
    color: var(--color-gray-100);
    font-size: 1.5rem;
    padding: 0.8rem;
    place-items: center;
    margin-bottom: 1.5rem;
    margin-inline: auto;
    border-radius: 1rem;
    transition: var(--transition);
}
.card h4
{
    display: block;
    margin-bottom: 3rem;
    font-weight: 300;
    color: #000;
    font-family: 'Roboto Slab', serif;
}
.card small
{
    margin-top: 8rem;
    display: block;
    font-weight: 300;
    letter-spacing: 1px;
    font-family: 'Anton', sans-serif;
}

/*** MEDIA QUERIES ***/
@media screen and (max-width:1024px){
    
    .container
    {
        width: var(--container-width-md);
    }
    h1
    {
        font-size: 2rem;
    }
    h2{
        font-size:1.6rem;
    }
}

@media screen and (max-width:600px)
{
    h1
    {
        font-size: 2.2rem;
        line-height: 1.3rem;
    }
}

@media screen and (max-width:768px){
    
    .section_head span
    {
        background: var(--color-gray-500);
        padding: 0.8rem;
        border-radius: 15rem;
        margin-left: 6rem;
        font-family: 'Akronim-Regular', serif;
        color: var(--color-secondary);
    }
    .section_head h2
    {
        font-family: 'Akronim-Regular', serif;
        font-size: 5rem;
        color: #000;
        font-weight: bold;
    }
    .icon
    {
        width: 50%;
        height: 50%;
        margin-top: 1rem;
        position: absolute;
        left: 40%;
    }
}
@media screen and (min-width:1200px) and (max-width:1444px)
{
    .card
    {
        background: #fff;
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.685);
        backdrop-filter: blur( 4.5px );
        -webkit-backdrop-filter: blur( 4.5px );
        border: 1px solid gray;
        text-align: center;
        width: 22vw;
        height: 40vh;
        margin-left: 0.1rem;
        border-radius: 10px;
        transition: var(--transition);
    }
    .card span
    {
        width: 10rem;
        height: 5rem;
        color: var(--color-gray-100);
        font-size: 1.5rem;
        padding: 0.8rem;
        margin-top: 2rem;
        place-items: center;
        margin-inline: auto;
        border-radius: 1rem;
        transition: var(--transition);
    }
    .icon
    {
        position: absolute;
        top: 10%;
        left: 25%;
    }
    .card h4
    {
        display: block;
        margin-bottom: 3rem;
        font-weight: 300;
        font-family: 'Roboto Slab', serif;
    }
    .card small
    {
        margin-top: 8rem;
        display: block;
        font-weight: 300;
        letter-spacing: 1px;
        font-family: 'Anton', sans-serif;
    }
    .btnbook
    {
        padding: 0.8rem 2rem;
        border-radius: 2rem;
        font-size: 1rem;
        position: absolute;
        top: 55%;
        left: 20%;
        margin-bottom: -1rem;
        color: #fff;
        background: var(--color-primary);
    }
    .btnservices
    {
        padding: 1rem 3rem;
        border-radius: 2rem;
        font-size: 1.1rem;
        margin-bottom: -1rem;
        color: #fff;
        left: 40%;
        text-align: center;
        background: var(--color-primary);
    }
}
@media screen and (max-width:1024px)
{
    .card
    {
        background: #fff;
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.685);
        backdrop-filter: blur( 4.5px );
        -webkit-backdrop-filter: blur( 4.5px );
        border: 1px solid gray;
        text-align: center;
        width: 35vw;
        height: 45vh;
        margin-left: 1rem;
        border-radius: 10px;
        transition: var(--transition);
    }
    .card span
    {
        width: 10rem;
        height: 5rem;
        color: var(--color-gray-100);
        font-size: 1.5rem;
        padding: 0.8rem;
        margin-top: 2rem;
        place-items: center;
        margin-inline: auto;
        border-radius: 1rem;
        transition: var(--transition);
    }
    .icon
    {
        position: absolute;
        top: 10%;
        left: 30%;
    }
    .card h4
    {
        display: block;
        margin-bottom: 3rem;
        font-weight: 300;
        font-family: 'Roboto Slab', serif;
    }
    .card small
    {
        margin-top: 8rem;
        display: block;
        font-weight: 300;
        letter-spacing: 1px;
        font-family: 'Anton', sans-serif;
    }
    .btnbook
    {
        padding: 1rem 1rem;
        border-radius: 2rem;
        font-size: 1rem;
        position: absolute;
        top: 55%;
        left: 30%;
        margin-bottom: -1rem;
        color: #fff;
        background: var(--color-primary);
    }
    .btnservices
    {
        padding: 1rem 3rem;
        border-radius: 2rem;
        font-size: 1.1rem;
        margin-bottom: -1rem;
        color: #fff;
        left: 40%;
        text-align: center;
        background: var(--color-primary);
    }
}
@media screen and (max-width:425px){
    
    .section_head span
    {
        background: var(--color-gray-500);
        padding: 0.3rem;
        border-radius: 4rem;
        margin-left: 2rem;
        font-family: 'Akronim-Regular', serif;
        color: var(--color-secondary);
    }
    .section_head h2
    {
        font-family: 'Akronim-Regular', serif;
        font-size: 3rem;
    }
    .icon
    {
        width: 50%;
        height: 50%;
        margin-top: 1rem;
        position: absolute;
        left: 33%;
    }
    .btnbook
    {
        padding: 1rem 1rem;
        border-radius: 2rem;
        font-size: 1rem;
        position: absolute;
        top: 50%;
        left: 30%;
        margin-bottom: -1rem;
        color: #fff;
        background: var(--color-primary);
    }
}
@media screen and (max-width:375px){
    
    .section_head span
    {
        background: var(--color-gray-500);
        padding: 0.3rem;
        border-radius: 4rem;
        margin-left: 2rem;
        font-family: 'Akronim-Regular', serif;
        color: var(--color-secondary);
    }
    .section_head h2
    {
        font-family: 'Akronim-Regular', serif;
        font-size: 3rem;
    }
    .icon
    {
        width: 50%;
        height: 50%;
        margin-top: 1rem;
        position: relative;
        left: 33%;
    }
    .btnbook
    {
        padding: 1rem 1rem;
        border-radius: 2rem;
        font-size: 1rem;
        position: absolute;
        top: 50%;
        left: 30%;
        margin-bottom: -1rem;
        color: #fff;
        background: var(--color-primary);
    }
}
@media screen and (max-width:768px)
{
    .card
    {
        background: #fff;
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.685);
        backdrop-filter: blur( 4.5px );
        -webkit-backdrop-filter: blur( 4.5px );
        border: 1px solid gray;
        text-align: center;
        width: 35vw;
        height: 30vh;
        margin-left: 1rem;
        border-radius: 10px;
        transition: var(--transition);
    }
    .card span
    {
        width: 8rem;
        height: 5rem;
        color: var(--color-gray-100);
        font-size: 1.5rem;
        padding: 0.8rem;
        margin-top: 2rem;
        place-items: center;
        margin-inline: auto;
        border-radius: 1rem;
        transition: var(--transition);
    }
    .icon
    {
        position: absolute;
        top: 10%;
        left: 20%;
    }
    .card h4
    {
        display: block;
        margin-bottom: 3rem;
        font-weight: 300;
        font-family: 'Roboto Slab', serif;
    }
    .card small
    {
        margin-top: 8rem;
        display: block;
        font-weight: 300;
        letter-spacing: 1px;
        font-family: 'Anton', sans-serif;
    }
    .btnbook
    {
        padding: 1rem 1rem;
        border-radius: 2rem;
        font-size: 1rem;
        position: absolute;
        top: 45%;
        left: 15%;
        margin-bottom: -1rem;
        color: #fff;
        background: var(--color-primary);
    }
    .btnservices
    {
        padding: 1rem 1rem;
        border-radius: 2rem;
        font-size: 1.1rem;
        margin-bottom: -1rem;
        color: #fff;
        margin-left: -14rem;
        text-align: center;
        background: var(--color-primary);
    }
}
@media screen and (max-width:425px)
{
    .card
    {
        background:#fff;
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.685);
        backdrop-filter: blur( 4.5px );
        -webkit-backdrop-filter: blur( 4.5px );
        border: 1px solid gray;
        text-align: center;
        width: 45vw;
        height: 30vh;
        border-radius: 10px;
        transition: var(--transition);
    }
    .card span
    {
        width: 7rem;
        height: 5rem;
        color: var(--color-gray-100);
        font-size: 1.5rem;
        padding: 0.8rem;
        place-items: center;
        margin-bottom: 1.5rem;
        margin-inline: auto;
        border-radius: 1rem;
        transition: var(--transition);
    }
    .card h4
    {
        display: block;
        margin-bottom: 3rem;
        font-weight: 300;
        font-size: 11px;
        font-family: 'Roboto Slab', serif;
    }
    .card small
    {
        margin-top: 4rem;
        display: block;
        font-weight: 300;
        font-size: 8px;
        font-family: 'Anton', sans-serif;
    }
    .btnbook
    {
        position: absolute;
        padding: 0.4rem;
        font-size: 8px;
        top: 8rem;
        border: 1px outset #fff;
        left: 3rem;
        text-align: center;
        color: #fff;
        background:var(--color-primary);
        box-shadow: 0 3px 2px 0 rgba(31, 38, 135, 0.685);
        backdrop-filter: blur( 4.5px );
        -webkit-backdrop-filter: blur( 4.5px );
        border-radius: 20px;
        border: 1px solid gray;
    }
    .icon
    {
        width: 15px;
        height: 10px;
        position: absolute;
        left: 1.5rem;
        top: 2rem;
    }
    .btnservices
    {
        padding: 1.2rem 1.2rem;
        border-radius: 2rem;
        font-size: 1rem;
        margin-bottom: -1rem;
        color: #fff;
        position: relative;
        left: 20rem;
        text-align: center;
        background: var(--color-primary);
    }
}