.about_container
{
    width: 100%;
    height: 100%;
    margin-top: 5rem;
    background: #fff;
}
.abouttitle
{
    text-align: center;
}
.abouttitle h1
{
    color:#000;
    font-size: 4rem;
    font-family: 'Acme', sans-serif;
    position: relative;
    top: 20px;
}
.about_story
{
    width: 100%;
    margin-top: 2rem;
    top: 10%;  
}
.aboutus
{
    margin-top: 3rem;
}
.story
{
    position:absolute;
    color: black;
    text-align: justify;
    top: 27%;
    left: 3%;
    right: 3%;
    font-size: 18px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.doit
{
    width: 100%;
    height: 30vh;
    align-items: center;
    margin-top: 2rem;
}
.doit h1
{
    position: absolute;
    left: 3%;
    right: 3%;
    color: #000;
    top: 54%;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: bold;
}
.howwe
{
    position: absolute;
    color: var(--color-gray-400);
    left: 3%;
    top: 57%;
    right: 3%;
    color: #000;
    text-align: justify;
    font-size: 18px;
}
.mission
{
    width: 100%;
    height: 30vh;
    margin-top: 1rem;
    align-items: center;
}
.mission h1
{
    position: absolute;
    left: 3%;
    color: #000;
    top: 76%;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: bold;
}
.mtitle
{
    position: absolute;
    color: #000;
    top: 80%;
    left: 3%;
    font-size: 18px;
}
.vision
{
    width: 100%;
    height: 35vh;
    margin-top: -3rem;
    align-items: center;
}
.vision h1
{
    position: absolute;
    left: 3%;
    top: 93%;
    font-size: 1.4rem;
    color: #000;
    text-transform: uppercase;
    font-weight: bold;
}
.vtitle
{
    position: absolute;
    color: #000;
    top: 101%;
    left: 3%;
    font-size: 18px;
}
.btext
{
    color: #000;
    text-align: center;
}
.abttitle
{
    color: #000;
    margin-top: 2rem;
    font-size: 2rem;
    text-align: center;
    margin-left: 2rem;
}
@media screen and (min-width:1200px) and (max-width:1444px)
{
    .abouttitle h1
    {
        color:#000;
        font-size: 4rem;
        font-family: 'Acme', sans-serif;
        position: relative;
        top: 30px;
    }  
    .about_story
    {
        width: 100%;
        margin-top: 2rem;
        top: 15%;
        margin-bottom: -10%;
    }
    .story
    {
        position: absolute;
        color: #000;
        top: 30%;
        left: 3%;
        right: 3%;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Times New Roman', Times, serif;
    }
    .doit h1
    {
        position: absolute;
        left: 3%;
        top: 60%;
        color: #000;
        font-size: 22px;
        font-family: 'Times New Roman', Times, serif;
        text-transform: uppercase;
        font-weight: bold;
    }
    .howwe
    {
        position: absolute;
        color: #000;
        top: 65%;
        text-align: justify;
        left: 3%;
        right: 3%;
        font-size: 18px;
        font-family: 'Times New Roman', Times, serif;
        font-weight: bold;
    }
    .mission h1
    {
        position: absolute;
        left: 3%;
        top: 78%;
        color: #000;
        font-size: 22px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .mtitle
    {
        position: absolute;
        color: #000;
        top: 83%;
        left: 3%;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Times New Roman', Times, serif;
    }
    .vision h1
    {
        position: absolute;
        left: 3%;
        top: 91%;
        color: #000;
        font-size: 22px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .vtitle
    {
        position: absolute;
        color: #000;
        top: 97%;
        left: 3%;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Times New Roman', Times, serif;
    }
    .btext
    {
        font-size: 8px;
        margin-top: 1rem;
    }
}
@media screen and (min-width:800px) and (max-width:1200px)
{
    .abouttitle h1
    {
        color:#000;
        font-size: 2rem;
        font-family: 'Acme', sans-serif;
        position: relative;
        top: 30px;
    }  
    .about_story
    {
        width: 100%;
        margin-top: 2rem;
        top: 5%;
        margin-bottom: -20%;
    }
    .story
    {
        position: absolute;
        color: #000;
        top: 23%;
        left: 3%;
        right: 3%;
        font-size: 15px;
        font-weight: bold;
        font-family: 'Times New Roman', Times, serif;
    }
    .doit h1
    {
        position: absolute;
        left: 3%;
        top: 46%;
        color: #000;
        font-size: 18px;
        font-family: 'Times New Roman', Times, serif;
        text-transform: uppercase;
        font-weight: bold;
    }
    .howwe
    {
        position: absolute;
        color: #000;
        top: 50%;
        text-align: justify;
        left: 3%;
        right: 3%;
        font-size: 15px;
        font-family: 'Times New Roman', Times, serif;
        font-weight: bold;
    }
    .mission h1
    {
        position: absolute;
        left: 3%;
        top: 64%;
        color: #000;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .mtitle
    {
        position: absolute;
        color: #000;
        top: 67%;
        left: 3%;
        font-size: 15px;
        font-weight: bold;
        font-family: 'Times New Roman', Times, serif;
    }
    .vision h1
    {
        position: absolute;
        left: 3%;
        top: 74%;
        color: #000;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .vtitle
    {
        position: absolute;
        color: #000;
        top: 78%;
        left: 3%;
        font-size: 15px;
        font-weight: bold;
        font-family: 'Times New Roman', Times, serif;
    }
    .btext
    {
        font-size: 8px;
        margin-top: 1rem;
    }
}
@media screen and (min-width:600px) and (max-width:800px)
{
    .abouttitle h1
    {
        color:#000;
        font-size: 2rem;
        font-family: 'Acme', sans-serif;
        position: relative;
        top: 30px;
    }  
    .about_story
    {
        width: 100%;
        margin-top: 2rem;
        top: 5%;
        margin-bottom: -60%;
    }
    .story
    {
        position: absolute;
        color: #000;
        top: 23%;
        left: 3%;
        right: 3%;
        font-size: 10px;
        font-weight: bold;
        font-family: 'Times New Roman', Times, serif;
    }
    .doit h1
    {
        position: absolute;
        left: 3%;
        top: 40%;
        color: #000;
        font-size: 14px;
        font-family: 'Times New Roman', Times, serif;
        text-transform: uppercase;
        font-weight: bold;
    }
    .howwe
    {
        position: absolute;
        color: #000;
        top: 43%;
        text-align: justify;
        left: 3%;
        right: 3%;
        font-size: 10px;
        font-family: 'Times New Roman', Times, serif;
        font-weight: bold;
    }
    .mission h1
    {
        position: relative;
        left: 3%;
        top: 1%;
        color: #000;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .mtitle
    {
        position: relative;
        color: #000;
        top: 3%;
        left: 3%;
        font-size: 10px;
        font-weight: bold;
        font-family: 'Times New Roman', Times, serif;
    }
    .vision h1
    {
        position: relative;
        left: 3%;
        top: -40%;
        color: #000;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .vtitle
    {
        position: relative;
        color: #000;
        top: -36%;
        left: 3%;
        font-size: 10px;
        font-weight: bold;
        font-family: 'Times New Roman', Times, serif;
    }
    .btext
    {
        font-size: 8px;
        margin-top: 1rem;
    }
}
@media screen and (max-width:425px)
{
    .abouttitle h1
    {
        color:#000;
        font-size: 2rem;
        font-family: 'Acme', sans-serif;
        position: relative;
        top: 30px;
    }  
    .about_story
    {
        width: 100%;
        margin-top: 2rem;
        top: 5%;
        margin-bottom: -40%;
    }
    .story
    {
        position: absolute;
        color: #000;
        top: 23%;
        left: 3%;
        right: 3%;
        font-size: 10px;
        font-weight: bold;
        font-family: 'Times New Roman', Times, serif;
    }
    .doit h1
    {
        position: absolute;
        left: 3%;
        top: 57%;
        color: #000;
        font-size: 14px;
        font-family: 'Times New Roman', Times, serif;
        text-transform: uppercase;
        font-weight: bold;
    }
    .howwe
    {
        position: absolute;
        color: #000;
        top: 61%;
        text-align: justify;
        left: 3%;
        right: 3%;
        font-size: 10px;
        font-family: 'Times New Roman', Times, serif;
        font-weight: bold;
    }
    .mission h1
    {
        position: relative;
        left: 3%;
        top: 60%;
        color: #000;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .mtitle
    {
        position: relative;
        color: #000;
        top: 61%;
        left: 3%;
        font-size: 10px;
        font-weight: bold;
        font-family: 'Times New Roman', Times, serif;
    }
    .vision h1
    {
        position: relative;
        left: 3%;
        top: 24%;
        color: #000;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .vtitle
    {
        position: relative;
        color: #000;
        top: 26%;
        left: 3%;
        font-size: 10px;
        font-weight: bold;
        font-family: 'Times New Roman', Times, serif;
    }
    .btext
    {
        font-size: 8px;
        margin-top: 1rem;
    }
    .abttitle
    {
        margin-top: 4rem;
    }
}
