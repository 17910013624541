.contact_container
{
    background: #fff;
    margin-top: 5rem;
    height: 100vh;
    width: 100%;
    text-align: center;
}
.ctitle
{
    text-align: center;
    position: relative;
    top: 17px;
    color: #000;
    font-size: 3rem;
    font-family: 'Acme', sans-serif;
}
.contact
{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin-top: 3rem;
}
.contimage
{
    position: relative;
    width: 80%;
    left: 10%;
}
.mail
{
    align-self: justify;
    position: absolute;
    font-size: 1.5rem;
    left: 52%;
    top: 28%;
    color: #000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}
.tmail
{
    align-self: justify;
    position: absolute;
    font-size: 1.5rem;
    left: 52%;
    top: 30%;
    color: #000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.trmail
{
    align-self: justify;
    position: absolute;
    font-size: 1.5rem;
    left: 52%;
    top: 52%;
    color: #000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.tphone
{
    align-self: justify;
    position: absolute;
    font-size: 1.5rem;
    left: 52%;
    top: 37%;
    color: #000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.phone
{
    align-self: justify;
    position: absolute;
    font-size: 1.5rem;
    left: 52%;
    top: 61%;
    color: #000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.tellphone
{
    align-self: justify;
    position: absolute;
    font-size: 1.5rem;
    left: 64%;
    top: 44%;
    color: #000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}
.caddress
{
    align-self: justify;
    position: absolute;
    font-size: 1.5rem;
    left: 50%;
    color: #000;
    top: 67%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.btext
{
    color: #000;
    text-align: center;
}
.contact_title
{
    color: #000;
    margin-top: 2rem;
    font-size: 2rem;
    text-align: center;
    margin-left: 2rem;
}
@media screen and (min-width:1200px) and (max-width:1444px)
{
    .contact_container
    {
        width: 100vw;
        height: 100%;
    }
    .ctitle
    {
        font-size: 2rem;
    }
    .contact
    {
        width: 100%;
        height: 30vh;
    }
    .mail
    {
        position:absolute;
        top: 28%;
        font-size: 1.4rem;
    }
    .tmail
    {
        position:absolute;
        top: 24%;
        font-size: 1.4rem;
    }
    .trmail
    {
        position:absolute;
        top: 50%;
        font-size: 1.4rem;
    }
    .contimage
    {
        position: absolute;
        width: 50%;
        height: 60%;
        left: 3px;
        top: 20%;
    }
    .phone
    {
        position:absolute;
        top: 59%;
        font-size: 22px;
    }
    .tellphone
    {
        position:absolute;
        top: 44%;
        font-size: 22px;
    }
    .tphone
    {
        position:absolute;
        top: 37%;
        font-size: 22px;
    }
    .caddress
    {
        position:absolute;
        top: 65%;
        color: #000;
        font-weight: bold;
        font-size: 25px;
    }
    .btext
    {
        font-size: 10px;
        margin-top: -1%;
    }
    .contact_title
    {
        margin-top: 20rem;
    }
}
@media screen and (min-width:800px) and (max-width:1200px)
{
    .contact_container
    {
        width: 100vw;
        height: 100%;
    }
    .ctitle
    {
        font-size: 2rem;
    }
    .contact
    {
        width: 100%;
        height: 30vh;
    }
    .mail
    {
        position:absolute;
        top: 28%;
        font-size: 1.4rem;
    }
    .tmail
    {
        position:absolute;
        top: 24%;
        font-size: 1.4rem;
    }
    .trmail
    {
        position:absolute;
        top: 50%;
        font-size: 1.4rem;
    }
    .contimage
    {
        position: absolute;
        width: 50%;
        height: 60%;
        left: 3px;
        top: 20%;
    }
    .phone
    {
        position:absolute;
        top: 59%;
        font-size: 18px;
    }
    .tellphone
    {
        position:absolute;
        top: 44%;
        font-size: 18px;
    }
    .tphone
    {
        position:absolute;
        top: 38%;
        font-size: 18px;
    }
    .caddress
    {
        position:absolute;
        top: 65%;
        color: #000;
        font-weight: bold;
        font-size: 18px;
    }
    .btext
    {
        font-size: 8px;
        margin-top: -1%;
    }
    .contact_title
    {
        margin-top: 12rem;
    }
}
@media screen and (max-width:768px)
{
    .contact_container
    {
        width: 100vw;
        height: 100%;
    }
    .ctitle
    {
        font-size: 2rem;
    }
    .contact
    {
        width: 100%;
        height: 30vh;
    }
    .mail
    {
        position:absolute;
        top: 20%;
        font-size: 1rem;
    }
    .tmail
    {
        position:absolute;
        top: 24%;
        font-size: 1rem;
    }
    .trmail
    {
        position:absolute;
        top: 35%;
        font-size: 15px;
    }
    .contimage
    {
        position: absolute;
        width: 50%;
        height: 25%;
        left: 3px;
    }
    .phone
    {
        position:absolute;
        top: 41%;
        font-size: 15px;
    }
    .tellphone
    {
        position:absolute;
        top: 30%;
        font-size: 15px;
    }
    .tphone
    {
        position:absolute;
        top: 26%;
        font-size: 15px;
    }
    .caddress
    {
        position:absolute;
        top: 45%;
        color: #000;
        font-weight: bold;
        font-size: 12px;
    }
    .btext
    {
        font-size: 8px;
        margin-top: -1%;
    }
}
@media screen and (max-width:425px)
{
    .contact_container
    {
        width: 100vw;
        height: 100%;
    }
    .ctitle
    {
        font-size: 2rem;
    }
    .contact
    {
        width: 100%;
        height: 30vh;
    }
    .mail
    {
        position:absolute;
        top: 20%;
        font-size: 10px;
    }
    .tmail
    {
        position:absolute;
        top: 24%;
        font-size: 9px;
    }
    .trmail
    {
        position:absolute;
        top: 35%;
        font-size: 9px;
    }
    .contimage
    {
        position: absolute;
        width: 50%;
        height: 25%;
        left: 3px;
    }
    .phone
    {
        position:absolute;
        top: 42%;
        font-size: 9px;
    }
    .tellphone
    {
        position:absolute;
        top: 31%;
        font-size: 9px;
    }
    .tphone
    {
        position:absolute;
        top: 27%;
        font-size: 9px;
    }
    .caddress
    {
        position:absolute;
        top: 45%;
        color: #000;
        font-weight: bold;
        font-size: 8px;
    }
    .btext
    {
        font-size: 8px;
        margin-top: -1%;
    }
    .contact_title
    {
        margin-top: 1rem;
        font-size: 2rem;
    }
}