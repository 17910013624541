.service_container
{
    margin-top: 5rem;
    background: #fff;
}
.banner:hover
{
    filter:grayscale(10);
    transition: ease-in;
}
.servtitle
{
    font-weight: bold;
    text-align: center;
}
.servtitle h1
{
    color: aqua;
    animation: animate 2s linear infinite;
    margin-top: 1rem;
    letter-spacing: 1px;
    text-shadow: 0.2rem 0.1rem blue;
    text-transform: uppercase;
    font-family: 'Acme', sans-serif;
}
.servicelist_container
{
    width: 100%;
    display: grid;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    background: #fff;
}
@keyframes animate
{
  0%
  {
    filter: hue-rotate(0deg);
  }
  100%
  {
    filter: hue-rotate(360deg);
  }
}
.servicelist
{
    display: grid;
    gap: 3rem;
    list-style-type: circle;
    margin-top: 4rem;
    grid-template-columns: repeat(4,1fr);
}
.services_card
{
    background: #fff;
    box-shadow: 0 8px 10px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4.5px );
    border: 2px solid var(--color-gray-100);
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 20vw;
    margin-left: 0.4rem;
    height: 35vh;
    align-content: center;
    justify-content: center;
    border-radius: 10px;
    transition: var(--transition);
}
.services_card h4
{
    margin-bottom: 3rem;
    font-weight: 300;
    margin-top: 2rem;
    color: #000;
    font-family: 'Roboto Slab', serif;
}
.services_card span
{
    width: 8rem;
    height: 5rem;
    color: var(--color-gray-100);
    font-size: 1.5rem;
    padding: 0.8rem;
    place-items: center;
    margin-bottom: 1.5rem;
}
.services_card small
{
    margin-top: 7rem;
    display: block;
    color: #000;
    font-size: 9px;
    font-weight: 300;
    position: relative;
    font-family: 'Anton', sans-serif;
}
.service_icon
{
    width: 15%;
    height: 30px;
    position: absolute;
    margin-left: 1rem;
    margin-top: 2.6rem;
}
.servicebtn
{
    position: absolute;
    padding: 0.7rem;
    font-size: 13px;
    margin-top: 4rem;
    border: 1px outset #fff;
    margin-left: -3.2rem;
    text-align: center;
    color: #fff;
    background:var(--color-primary);
    box-shadow: 0 4px 8px 0 rgba(31, 38, 135, 0.685);
    backdrop-filter: blur( 4.5px );
    -webkit-backdrop-filter: blur( 4.5px );
    border-radius: 50px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.servicebtn:hover
{
    border: 2px double white;
}
.wtitle
{
    color: #000;
    margin-top: 2rem;
    font-size: 2rem;
    text-align: center;
    margin-left: 2rem;
}
.wimgs
{
    display: grid;
    margin-top: 2rem;
    grid-template-columns: repeat(4,1fr);
    gap: 3rem;
    margin-bottom: 2rem;
}
.bank
{
    width: 80%;
    margin-left: 4rem;
    margin-top: 3.5rem;
}
.mahal
{
    width: 60%;
    margin-top: 2rem;
    margin-left: 5rem;
}
.finance
{
    width: 100%;
    margin-top: -1rem;
    margin-left: -1rem;
}
.cvd
{
    width: 60%;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
}
@media screen and (min-width:1200px) and (max-width:1444px)
{
    .servicelist
    {   
        display: grid;
        gap: 1rem;
        margin-left: 2rem;
        list-style-type: circle;
        margin-top: 2rem;
        grid-template-columns: repeat(3,1fr);
    }
    .services_card
    {
        background:#fff;
        box-shadow: 0 3px 8px 0 rgba(31, 38, 135, 0.685);
        border: 1px solid gray;
        text-align: center;
        width: 25vw;
        height: 45vh;
        border-radius: 10px;
        transition: var(--transition);
    }
    .services_card h4
    {
        margin-bottom: 3rem;
        font-weight: 300;
        margin-top: 2rem;
        color: #000;
        font-size: 1.5rem;
        font-family: 'Roboto Slab', serif;
    }
    .services_card span
    {
        width: 10rem;
        height: 5rem;
        color: var(--color-gray-100);
        font-size: 1.5rem;
        padding: 0.8rem;
        place-items: center;
        margin-bottom: 1.5rem;
        margin-inline: auto;
        border-radius: 1rem;
        transition: var(--transition);
    }
    .servicebtn
    {
        position: absolute;
        padding: 0.7rem 2rem;
        font-size: 1rem;
        margin-top: 6rem;
        border: 1px outset #fff;
        margin-left: -6rem;
        text-align: center;
        color: #fff;
        background:var(--color-primary);
        box-shadow: 0 3px 2px 0 rgba(31, 38, 135, 0.685);
        backdrop-filter: blur( 4.5px );
        -webkit-backdrop-filter: blur( 4.5px );
        border-radius: 20px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
    }
    .service_icon
    {
        width: 15px;
        height: 10px;
        position: absolute;
        left: 6rem;
        top: 10%;
    }
}
@media screen and (max-width:1024px)
{
    .servicelist
    {   
        display: grid;
        gap: 1rem;
        margin-left: 2rem;
        list-style-type: circle;
        margin-top: 2rem;
        grid-template-columns: repeat(3,1fr);
    }
    .services_card
    {
        background:#fff;
        box-shadow: 0 3px 8px 0 rgba(31, 38, 135, 0.685);
        border: 1px solid gray;
        text-align: center;
        width: 25vw;
        height: 35vh;
        border-radius: 10px;
        transition: var(--transition);
    }
    .services_card span
    {
        width: 8rem;
        height: 5rem;
        color: var(--color-gray-100);
        font-size: 1.5rem;
        padding: 0.8rem;
        place-items: center;
        margin-bottom: 1.5rem;
        margin-inline: auto;
        border-radius: 1rem;
        transition: var(--transition);
    }
    .servicebtn
    {
        position: absolute;
        padding: 0.7rem 2rem;
        font-size: 0.8rem;
        margin-top: 5rem;
        border: 1px outset #fff;
        margin-left: -5rem;
        text-align: center;
        color: #fff;
        background:var(--color-primary);
        box-shadow: 0 3px 2px 0 rgba(31, 38, 135, 0.685);
        backdrop-filter: blur( 4.5px );
        -webkit-backdrop-filter: blur( 4.5px );
        border-radius: 20px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
    }
    .service_icon
    {
        width: 15px;
        height: 10px;
        position: absolute;
        left: 4.5rem;
        top: 10%;
    }
}
@media screen and (max-width:768px)
{
    .servicelist
    {   
        display: grid;
        gap: 0.1rem;
        list-style-type: circle;
        margin-top: 2rem;
        margin-left: 2rem;
        grid-template-columns: repeat(2,1fr);
    }
    .services_card
    {
        background:#fff;
        box-shadow: 0 3px 8px 0 rgba(31, 38, 135, 0.685);
        border: 1px solid gray;
        text-align: center;
        width: 40vw;
        height: 30vh;
        border-radius: 10px;
        transition: var(--transition);
    }
    .services_card span
    {
        width: 10rem;
        height: 5rem;
        color: var(--color-gray-100);
        font-size: 1.5rem;
        padding: 0.8rem;
        place-items: center;
        margin-bottom: 1.5rem;
        margin-inline: auto;
        border-radius: 1rem;
        transition: var(--transition);
    }
    .services_card h4
    {
        display: block;
        margin-bottom: 3rem;
        font-weight: 300;
        font-size: 11px;
        font-family: 'Roboto Slab', serif;
    }
    .services_card small
    {
        margin-top: 4rem;
        display: block;
        font-weight: 300;
        font-size: 8px;
        font-family: 'Anton', sans-serif;
    }
    .servicebtn
    {
        position: absolute;
        padding: 0.4rem;
        font-size: 8px;
        margin-top: 7rem;
        border: 1px outset #fff;
        margin-left: -2rem;
        text-align: center;
        color: #fff;
        background:var(--color-primary);
        box-shadow: 0 3px 2px 0 rgba(31, 38, 135, 0.685);
        backdrop-filter: blur( 4.5px );
        -webkit-backdrop-filter: blur( 4.5px );
        border-radius: 20px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
    }
    .service_icon
    {
        width: 15px;
        height: 10px;
        position: absolute;
        left: 2.5rem;
    }
    .finance
    {
        margin-top: 0.7rem;
        margin-left: 1rem;
    }
}
@media screen and (max-width:425px)
{
    .servicelist
    {   
        display: grid;
        gap: 0.1rem;
        margin-left: 0.1rem;
        list-style-type: circle;
        margin-top: 2rem;
        grid-template-columns: repeat(2,1fr);
    }
    .services_card
    {
        background:#fff;
        box-shadow: 0 3px 8px 0 rgba(31, 38, 135, 0.685);
        border: 1px solid gray;
        text-align: center;
        width: 45vw;
        height: 30vh;
        border-radius: 10px;
        transition: var(--transition);
    }
    .services_card span
    {
        width: 8rem;
        height: 5rem;
        color: var(--color-gray-100);
        font-size: 1.5rem;
        padding: 0.8rem;
        place-items: center;
        margin-top: 1.5rem;
        margin-inline: auto;
        border-radius: 1rem;
        transition: var(--transition);
    }
    .services_card h4
    {
        display: block;
        margin-bottom: 3rem;
        font-weight: 300;
        font-size: 11px;
        font-family: 'Roboto Slab', serif;
    }
    .services_card small
    {
        margin-top: 4rem;
        display: block;
        font-weight: 300;
        font-size: 8px;
        font-family: 'Anton', sans-serif;
    }
    .servicebtn
    {
        position: absolute;
        padding: 0.4rem;
        font-size: 8px;
        margin-top: 5rem;
        border: 1px outset #fff;
        margin-left: -2rem;
        text-align: center;
        color: #fff;
        background:var(--color-primary);
        box-shadow: 0 3px 2px 0 rgba(31, 38, 135, 0.685);
        backdrop-filter: blur( 4.5px );
        -webkit-backdrop-filter: blur( 4.5px );
        border-radius: 20px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
    }
    .service_icon
    {
        width: 15px;
        height: 10px;
        position: absolute;
        left: 1rem;
        top: 1rem;
    }
    .wtitle
    {
        font-size: 2rem;
        margin-left: 1rem;
        text-align: center;
    }
    .bank
    {
        width: 100%;
        margin-top: 2.8rem;
        margin-left: 3rem;
    }
    .mahal
    {
        width: 90%;
        margin-left: 1.5rem;
    }
    .finance
    {
        width: 150%;
        margin-left: -1.2rem;
        margin-top: 0.9rem;
    }
    .cvd
    {
        width: 90%;
        margin-left: -2rem;
    }
}