.center_container
{
    margin-top: 5rem;
}
.ttitle
{
  color: #000;
  text-align: center;
  position: relative;
  top: 2rem;
}
.aboutlines
{
    color: #000;
    position: relative;
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-left: 1rem;
    top: 4rem;
}
.course_container
{
  width: 100%;
  display: grid;
  position: absolute;
  top: 120%;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.course_container h1
{
  color: #000;
  text-align: center;
  margin-top: 2rem;
}
.courselist
{
    display: grid;
    gap: 3rem;
    list-style-type: circle;
    margin-top: 1rem;
    grid-template-columns: repeat(4,1fr);
}
.enquirybtn
{
  position: relative;
  padding: 0.7rem 2.4rem;
  font-size: 1rem;
  margin-top: 0.9rem;
  border: 1px outset #fff;
  margin-left: 0.8rem;
  text-align: center;
  color: #fff;
  background:var(--color-primary);
  box-shadow: 0 3px 2px 0 rgba(31, 38, 135, 0.685);
  backdrop-filter: blur( 4.5px );
  -webkit-backdrop-filter: blur( 4.5px );
  border-radius: 20px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.main-review-wrapper
{
  width: 100%;
  margin-top: 27rem;
  position: absolute;
  border-top: 1px solid black;
}
.main-review-wrapper h1
{
  color: #000;
  text-align: center;
  margin-top: 1rem;
  font-size: 2.4rem;
}
.reviewlist
{
  width: 50%;
  margin-top: 12rem;
  margin-left: 20rem;
}
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}
.course_Card .list
{
  color: #000;
  font-size: 15px;
  position: relative;
  font-weight: bold;
  top:-10%;
  text-align: center;
}
.atitle
{
  margin-top: 2rem;
  font-size: 3rem;
  color: #000;
  text-align: center;
}
.bimgs
{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  margin-bottom: 3rem;
}
.bosch
{
  width: 100%;
  margin-left: 2rem;
}
.boschbanner
{
  width: 60%;
  margin-top: 3rem;
  margin-left: 3rem;
}
@media screen and (min-width:1200px) and (max-width:1444px)
{
  .course_container
  {
    width: 100%;
    display: grid;
    position: absolute;
    top: 120%;
    align-items: center;
    justify-content: center;
    background: #fff;
  }
  .courselist
  {
    display: grid;
    gap: 1.5rem;
    list-style-type: circle;
    margin-top: 3rem;
    grid-template-columns: repeat(3,1fr);
  }
  .course_Card .list
  {
    color: #000;
    font-size: 15px;
    position: relative;
    font-weight: bold;
    top:-10%;
    text-align: center;
  }
  .main-review-wrapper
  {
    position: relative;
    width: 100%;
    top: 50rem;
    border-top: 1px solid black;
  }
  .atitle
  {
    font-size: 2rem;
  }
  .reviewlist
  {
    margin-left: 19rem;
    width: 50%;
  }
  .bosch
  {
    margin-top: 2rem;
    width: 60%;
    margin-left: 40%;
  }
  .boschbanner
  {
    margin-top: 4rem;
    width: 50%;
  }
}
@media screen and (min-width:800px) and (max-width:1200px)
{
  .course_container
  {
    width: 100%;
    display: grid;
    position: absolute;
    top: 105%;
    align-items: center;
    justify-content: center;
    background: #fff;
  }
  .courselist
  {
    display: grid;
    gap: 1.5rem;
    list-style-type: circle;
    margin-top: 1rem;
    grid-template-columns: repeat(3,1fr);
  }
  .course_Card .list
  {
    color: #000;
    font-size: 15px;
    position: relative;
    font-weight: bold;
    top:-10%;
    text-align: center;
  }
  .main-review-wrapper
  {
    position: relative;
    width: 100%;
    top: 40rem;
    border-top: 1px solid black;
  }
  .reviewlist
  {
    margin-left: 5rem;
    width: 70%;
  }
  .bosch
  {
    margin-top: 1rem;
  }
}
@media screen and (min-width:600px) and (max-width:800px)
{
  .course_container
  {
    width: 100%;
    display: grid;
    position: absolute;
    top: 145%;
    align-items: center;
    justify-content: center;
    background: #fff;
  }
  .courselist
  {
    display: grid;
    gap: 3rem;
    list-style-type: circle;
    margin-top: 1rem;
    grid-template-columns: repeat(2,1fr);
  }
  .course_Card .list
  {
    color: #000;
    font-size: 10px;
    position: relative;
    font-weight: bold;
    top:-10%;
    text-align: center;
  }
  .main-review-wrapper
  {
    width: 100%;
    margin-top: 130rem;
    position: relative;
    border-top: 1px solid black;
  }
  .reviewlist
  {
    margin-left: 5rem;
    width: 70%;
  }
  .bosch
  {
    margin-top: 1rem;
  }
}
@media screen and (max-width:425px)
{
  .ttitle
  {
    color: #000;
    text-align: center;
    position: relative;
    top: 3rem;
    font-size: 1.8rem;
  }
  .aboutlines
  {
      position: relative;
      font-size: 10px;
      margin-top: 1rem;
  }
  .courselist
  {   
      display: grid;
      gap: 0.7rem;
      list-style-type: circle;
      margin-top: 2rem;
      grid-template-columns: repeat(2,1fr);
  }
  .course_container h1
  {
    margin-top: 6rem;
  }
  .course_Card h4
  {
    font-size: 1rem;
  }
  .course_Card .list
  {
    color: #000;
    font-size: 10px;
    position: relative;
    font-weight: bold;
    top:-20%;
    text-align: center;
  }
  .enquirybtn
  {
    position: relative;
    padding: 0.7rem 0.7rem;
    font-size: 10px;
    top: -18%;
    border: 1px outset #fff;
    margin-left: 0.8rem;
    text-align: center;
    color: #fff;
    background:var(--color-primary);
    box-shadow: 0 3px 2px 0 rgba(31, 38, 135, 0.685);
    backdrop-filter: blur( 4.5px );
    -webkit-backdrop-filter: blur( 4.5px );
    border-radius: 20px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
  }
  .main-review-wrapper
  {
    width: 100%;
    border-top: 1px solid black;
    height: 40vh;
    top: 58rem;
    position: relative;
  }
  .main-review-wrapper h1
  {
    color: #000;
    font-size: 1rem;
    margin-top: 1rem;
    text-align: center
  }
  .reviewlist
  {
    width: 90%;
    margin-top: 11rem;
    margin-left: 1rem;
  }
  .atitle
  {
    font-size: 1rem;
  }
  .bosch
  {
    width: 60%;
    margin-top: 0.6rem;
    margin-left: 4rem;
  }
  .boschbanner
  {
    width: 40%;
    margin-top: 1rem;
    margin-left: 2rem;
  }
}