nav
{
    height: 5rem;
    width: 100vw;
    background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
}
.nav_toggle-btn
{
    display: none;
}
.nav_container
{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.title
{
    font-size: 25px;
    line-height: 2.5;
    position: fixed;
    left: 17%;
    letter-spacing: 1px;
}
.logo
{
    width: 60px;
    position: relative;
}
.nav_links
{
    display: flex;
    gap: 2.5rem;
    
}
.nav_links a
{
    transition: var(--transistion);
}
.nav_links a:hover
{
    color: var(--color-primary);
}
.active-nav
{
    position: relative;
}
.active-nav::after
{
    content: '';
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    font-family: 'Secular One', sans-serif;
    background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
    position: absolute;
    left: calc(50% - 0.6rem);
    transform: rotate(45deg);
    margin-top: 0.9rem;
}
/* Media Queries*/
@media screen and (max-width:1024px)
{
    .nav_toggle-btn
    {
        display: inline-block;
        background: transparent;
        font-size: 1.9rem;
        cursor: pointer;
    }
    .nav_toggle-btn svg
    {
        color: var(--color-gray-100);
    }
    .title
    {
        position: fixed;
        left: 23%;
    }
    .logo
    {
        position: relative;
        left: 5%;
    }
    .nav_links
    {
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
        perspective: 400px;
    }
    .active-nav, .active-nav::after
    {
        display: none;

    }
    .nav_links li
    {
        height: 4rem;
        width: 100%;
        box-shadow: -2rem 2rem 5rem rgba(0,0,0,0.5);
        animation: navAnimation 600ms ease forwards;
        transform: rotateX(90deg);
        opacity: 0;
        transform-origin: top;
    }
    .nav_links li:nth-child(2)
    {
        animation-delay: 200ms;
    }
    .nav_links li:nth-child(3)
    {
        animation-delay: 400ms;
    }
    .nav_links li:nth-child(4)
    {
        animation-delay: 600ms;
    }
    .nav_links li:nth-child(5)
    {
        animation-delay: 800ms;
    }
    .nav_links li:nth-child(6)
    {
        animation-delay: 1s;
    }

    @keyframes navAnimation {
        to{
            transform: rotate(0);
            opacity: 1;
        }
    }
    .nav_links li a
    {
        background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.36);
        height: 100%;
        width: 100%;
        font-size: 1.3rem;
        color: white;
        text-shadow: 2px 1px #000;
        font-weight: bold;
        font-family: 'Secular One', sans-serif;
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;
    }
    .show_nav
    {
        display: flex;
    }
    .hide_nav
    {
        display: none;
    }
}
@media screen and (min-width:600px) and (max-width:800px)
{
    
    .title
    {
        position: fixed;
        left: 20%;
    }
}
@media screen and (max-width:425px)
{
    
    .title
    {
        position: fixed;
        left: 28%;
    }
}